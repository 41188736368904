import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from './../../components/help/Layout/Layout'
import Seo from './../../components/help/Seo'
import Header from './../../components/ui/Header/Header'
import HeaderFixed from './../../components/ui/HeaderFixed/HeaderFixed'
import { getHrefToHome } from '../../utils/getHrefToHome'
import Policy from './../../components/pages/Policy/Policy'
import PageNavigation from './../../components/ui/PageNavigation/PageNavigation'

const PolicyPage = () => {
    const lang = 'en'

    const hrefHome = getHrefToHome(lang)

    const data = useStaticQuery(graphql`
        {
            blockStart: datoCmsRenshinPrivacyPolicy(titleSystem: { eq: "Block start" }, locale: { eq: "en" }) {
                text
                title
            }

            blockEnd: datoCmsRenshinPrivacyPolicy(titleSystem: { eq: "Block end" }, locale: { eq: "en" }) {
                text
                title
            }

            items: allDatoCmsRenshinPrivacyPolicy(
                sort: { fields: [sortingBlockWithNumber], order: ASC }
                filter: { locale: { eq: "en" }, titleSystem: { nin: ["Block start", "Block end"] } }
            ) {
                nodes {
                    title
                    text
                }
            }

            seo: datoCmsRenshinSeo(pageName: { eq: "Privacy policy" }, locale: { eq: "en" }) {
                titleH1
                crumbsName

                footerLinks {
                    title
                    url
                }
                seo {
                    title
                    description
                }
            }

            main: datoCmsRenshinSeo(pageName: { eq: "Main" }, locale: { eq: "en" }) {
                crumbsName
            }
        }
    `)

    const breadCrumbs = [
        { name: `${data.main?.crumbsName}`, href: `${hrefHome}`, current: false },
        { name: `${data.seo?.crumbsName}`, current: true }
    ]

    return (
        <Layout lang={lang}>
            <Seo title={data.seo?.seo?.title} description={data.seo?.seo?.description} />
            <Header lang={lang} />
            <HeaderFixed lang={lang} light />
            <Policy crumbs={breadCrumbs} data={data} lang={lang} />
            <PageNavigation links={data.seo.footerLinks} lang={lang} />
        </Layout>
    )
}

export default PolicyPage
